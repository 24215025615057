<template>
  <div>
    <portal to="body-top">
      <div class="d-flex justify-content-center">
        <b-skeleton
          width="40%"
          height="26px"
          class="mb-0"
        />
      </div>
      <quote-status-bar-skeleton />
    </portal>
    <div class="py-1 mt-1">
      <b-row>
        <b-col md="6">
          <b-col
            class="d-flex align-items-center justify-content-between"
          >
            <span class="w-50 font-weight-bolder mb-1">
              {{ $t('Origin Warehouse') }}
            </span>
            <span class="w-50">
              <b-skeleton class=" w-100 h-auto" />
            </span>
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-between"
          >
            <span class="w-50 font-weight-bolder mb-1">
              {{ $t('Scheduled Dispatch') }}
            </span>
            <span class="w-50">
              <b-skeleton class=" w-100 h-auto" />
            </span>
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-between"
          >
            <span class="w-50 font-weight-bolder mb-1">
              {{ $t('Received') }}
            </span>
            <span class="w-50">
              <b-skeleton class=" w-100 h-auto" />
            </span>
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-between"
          >
            <span class="w-50 font-weight-bolder mb-1">
              {{ $t('Receiving Warehouse') }}
            </span>
            <span class="w-50">
              <b-skeleton class=" w-100 h-auto" />
            </span>
          </b-col>
        </b-col>
        <b-col
          md="6"
        >
          <b-skeleton-img
            no-aspect
            class="w-100 rounded"
            style="height: 150px"
          />
        </b-col>
      </b-row>
    </div>
    <div class="py-1 rounded">
      <h1
        class="font-weight-bolder font-medium-5 pl-1"
      >
        {{ $t('Asset(s)') }}
      </h1>
      <b-skeleton-table
        :rows="4"
        :columns="6"
        :table-props="{ bordered: false, striped: false }"
      />
    </div>
    <portal to="body-footer">
      <div class="d-flex mt-2 pb-1 justify-content-between">
        <div>
          <b-button
            :disabled="true"
            class="font-medium-1 font-weight-bolder"
            variant="outline-primary"
            style="width: 170px; height: 40px;"
          >
            {{ $t('Back to List') }}
          </b-button>
        </div>
        <div class="d-flex align-items-center">
          <b-button
            variant="danger"
            class="d-flex align-items-center justify-content-center font-medium-1 font-weight-bolder mr-1"
            style="width: 170px; height: 40px;"
            :disabled="true"
          >
            <feather-icon
              icon="LNoIcon"
              size="18"
              style="margin-right: 3px"
            />
            {{ $t('Void') }}
          </b-button>
          <b-button
            variant="success"
            class="d-flex align-items-center justify-content-center font-medium-1 font-weight-bolder mr-1"
            style="width: 170px; height: 40px;"
            :disabled="true"
          >
            <feather-icon
              icon="LPrintIcon"
              size="18"
              style="margin-right: 3px"
            />
            {{ $t('Print') }}
          </b-button>
          <b-button
            variant="success"
            class="d-flex align-items-center justify-content-center font-medium-1 font-weight-bolder"
            style="width: 170px; height: 40px;"
            :disabled="true"
          >
            {{ $t('Release Items') }}
          </b-button>
        </div>
      </div>
    </portal>
  </div>
</template>
<script>
import {
  BButton,
  BCol, BRow, BSkeleton, BSkeletonImg, BSkeletonTable,
} from 'bootstrap-vue'
import QuoteStatusBarSkeleton from '@/views/main/orders/components/use-as-global/QuoteStatusBarSkeleton.vue'

export default {
  name: 'PermanentTransferSkeleton',
  components: {
    QuoteStatusBarSkeleton,
    BButton,
    BSkeletonTable,
    BSkeletonImg,
    BRow,
    BCol,
    BSkeleton,
  },
}
</script>
