<template>
  <div class="bg-white p-1 mt-1 rounded-lg">
    <h1
      class="font-weight-bolder font-medium-5 pl-1"
    >
      {{ $t('Asset(s)') }}
    </h1>
    <l-table-list-collector
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="assetsTableColumns"
      :table-suggestion-configs="autoSuggestTableColumns"
      :fetched-data="inventoryTransferOrderInformation.assets"
      :is-searchable="isSearchable && isChange"
      :is-pagination="true"
      :is-footer="true"
      :row-table-footer="true"
      :can-create="ACCESS_TRANSFER_SCHEDULE_ASSET"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/getAssetList`
      }"
      @getCollectedList="getCollectedList"
    >
      <template #pagination>
        {{ }}
      </template>
      <template
        v-if="!isPrint"
        #filter
      >
        <feather-icon
          icon="LAddButtonIcon"
          class="cursor-pointer"
          size="34"
          @click="addHandler"
        />
      </template>
      <template
        #cell(order_type)="{data: {item}}"
      >
        <div>
          {{ getValueFromGivenObjectByKey(item, 'order_type', '—') }}
        </div>
        <div class="pt-1">
          {{ getValueFromGivenObjectByKey(item, 'comments', '—') }}
        </div>
      </template>
      <template #cell(sku)="{data: {item}}">
        <div class="pl-2">
          {{ getValueFromGivenObjectByKey(item, 'sku', '—') }}
        </div>
        <div
          class="pt-1"
          style="height: 35px"
        />
      </template>
      <template #cell(asset_id)="{data: {item}}">
        <div class="pl-2">
          {{ getValueFromGivenObjectByKey(item, 'inventory_item.id', '—') }}
        </div>
        <div
          class="pt-1"
          style="height: 35px"
        />
      </template>
      <template #cell(name)="{data: {item}}">
        <div class="pl-2">
          {{ getValueFromGivenObjectByKey(item, 'name', '—') }}
        </div>
        <div
          class="pt-1"
          style="height: 35px"
        />
      </template>

      <template #cell(purpose)="{data: {item}}">
        <div class="pl-1">
          {{ getValueFromGivenObjectByKey(item, 'purpose', '—') }}
        </div>
        <div
          class="pt-1"
          style="height: 35px"
        />
      </template>
      <template #cell(request_by)="{data: {item}}">
        <div class="pl-3">
          {{ getValueFromGivenObjectByKey(item, 'requested_by', '—') }}
        </div>
        <div
          class="pt-1"
          style="height: 35px"
        />
      </template>

    </l-table-list-collector>
  </div>
</template>
<script>
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import config from '@/views/main/orders/view/transfer/permanentTransferScheduled/permanentTransferByAssetId/config'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'

export default {
  name: 'AssetsTable',
  components: { LTableListCollector },
  props: {
    isChange: {
      type: Boolean,
      default: false,
    },
    isPrint: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSearchable: false,
    }
  },
  computed: {
    inventoryTransferOrderInformation() {
      return this.$store.state[this.MODULE_NAME].transferOrderScheduledInformation
    },
  },
  methods: {
    getValueFromGivenObjectByKey,
    addHandler() {
      this.isSearchable = true
      this.$emit('isClickAddButton', this.isSearchable)
    },
    fetchedItem(data) {
      const newArr = []
      data.forEach(itemOne => {
        itemOne.data.forEach(({
          stock, warehouse, sku, id,
        }) => {
          newArr.push({
            stock, warehouse, sku, asset_id: id,
          })
        })
      })
      return newArr
    },
    getCollectedList(list, item) {
      this.inventoryTransferOrderInformation.assets[this.inventoryTransferOrderInformation.assets.length - 1] = {
        id: item.id,
        sku: item.product.sku,
        name: item.name || item.product.name,
        asset_id: item.asset_id || item.id,
        amount: item.amount,
        type: item.type || 'Permanent',
      }
      this.$emit('isAddItem', this.isSearchable)
    },
  },
  setup() {
    const {
      MODULE_NAME, autoSuggestTableColumns, assetsTableColumns, ACCESS_TRANSFER_SCHEDULE_ASSET,
    } = config()

    return {
      MODULE_NAME,
      assetsTableColumns,
      autoSuggestTableColumns,
      ACCESS_TRANSFER_SCHEDULE_ASSET,
    }
  },
}
</script>
