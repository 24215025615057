<template>
  <div class="bg-white p-1 rounded-lg">
    <h1
      v-if="!isPrint"
      class="font-weight-bolder font-medium-5 pl-1"
    >
      {{ $t('Transfer Schedule') }}
    </h1>
    <b-row>
      <b-col md="6">
        <b-col
          class="d-flex align-items-center justify-content-between"
        >
          <span class="w-50 font-weight-bolder mb-1">
            {{ $t('Origin Warehouse') }}
          </span>
          <span class="w-50 mb-1">
            {{ inventoryTransferOrderInformation.shipping_warehouse.label }}
          </span>
        </b-col>
        <b-col
          class="d-flex align-items-center justify-content-between"
        >
          <span class="w-50 font-weight-bolder mb-1">
            {{ $t('Scheduled Dispatch') }}
          </span>
          <span class="w-50 mb-1">
            {{ inventoryTransferOrderInformation.scheduled_dispatch }} {{ inventoryTransferOrderInformation.transfer_time }}
          </span>
        </b-col>
        <b-col
          class="d-flex align-items-center justify-content-between"
        >
          <span class="w-50 font-weight-bolder mb-1">
            {{ $t('Received') }}
          </span>
          <span class="w-50 mb-1">
            {{ '-' }}
          </span>
        </b-col>
        <b-col
          class="d-flex align-items-center justify-content-between"
        >
          <span class="w-50 font-weight-bolder mb-1">
            {{ $t('Receiving Warehouse') }}
          </span>
          <span class="w-50 mb-1">
            {{ inventoryTransferOrderInformation.receiving_warehouse.label }}
          </span>
        </b-col>
        <b-col
          v-if="isPrint"
          class="d-flex align-items-center justify-content-between"
        >
          <span class="w-50 font-weight-bolder mb-1">
            {{ $t('Transfer Order comments') }}
          </span>
          <span class="w-50">
            {{ inventoryTransferOrderInformation.comments }}
          </span>
        </b-col>
      </b-col>
      <b-col
        v-if="!isPrint"
        md="6"
      >
        <component
          :is="transferScheduleFields['transfer_order_comments'].type"
          v-model="inventoryTransferOrderInformation.comment"
          v-bind="getProps('transfer_order_comments', transferScheduleFields)"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import { getProps } from '@core/utils/utils'
import config from '@/views/main/orders/view/transfer/permanentTransferScheduled/permanentTransferByAssetId/config'

export default {
  name: 'TransferSchedule',
  components: {
    BRow,
    BCol,
  },
  props: {
    isPrint: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inventoryTransferOrderInformation() {
      return this.$store.state[this.MODULE_NAME].transferOrderScheduledInformation
    },
  },
  methods: { getProps },
  setup() {
    const { MODULE_NAME, transferScheduleObjectForRender, transferScheduleFields } = config()

    return {
      MODULE_NAME,
      transferScheduleObjectForRender,
      transferScheduleFields,
    }
  },
}
</script>
