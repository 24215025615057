<template>
  <div class="ml-2">
    <div class="mb-4">
      <img
        :src="ErboLogo"
        alt="aw"
      >
    </div>
    <h4
      class="text-center font-weight-bolder mb-4"
    >
      {{ $t(transferOrderInformation.order_id) }}{{ `—${transferOrderInformation.origin_warehouse} to ${transferOrderInformation.receiving_warehouse.label}` }}
    </h4>
    <quote-status-bar
      :item="quote"
      class="mb-4"
    />
    <transfer-schedule :is-print="true" />
    <assets-table :is-print="true" />
  </div>
</template>
<script>
import ErboLogo from '@/assets/images/ico/erbo-logo.svg'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import config from '@/views/main/orders/view/transfer/permanentTransferScheduled/permanentTransferByAssetId/config'
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import AssetsTable from './AssetsTable.vue'
import TransferSchedule
from './TransferSchedule.vue'

export default {
  components: { AssetsTable, TransferSchedule, QuoteStatusBar },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME].quoteTransferOrder
    },
    transferOrderInformation() {
      return this.$store.state[this.MODULE_NAME].transferOrderScheduledInformation
    },
  },

  setup() {
    const { MODULE_NAME, SCHEDULED_TITLE_PREFIX } = config()
    return {
      MODULE_NAME,
      ErboLogo,
      SCHEDULED_TITLE_PREFIX,
      getValueFromGivenObjectByKey,
    }
  },
}
</script>
