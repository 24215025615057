import { TEXTAREA_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  const MODULE_NAME = 'orders'
  const ACCESS_TRANSFER_SCHEDULE_ASSET = { action: 'view', subject: 'Catalog' }
  const SCHEDULED_TITLE_PREFIX = 'Scheduled'
  const VOID_ACTION = 5
  const transferScheduleObjectForRender = [
    {
      key: 'origin_warehouse',
      label: 'Origin Warehouse',
    },
    {
      key: 'scheduled_dispatch',
      label: 'Scheduled Dispatch',
    },
    {
      key: 'received',
      label: 'Received',
    },
    {
      key: 'receiving_warehouse',
      label: 'Receiving Warehouse',
    },
  ]
  const transferScheduleFields = {
    transfer_order_comments: {
      type: TEXTAREA_INPUT,
      label: 'Transfer Order comments',
      placeholder: 'Write notes here...',
      options: {
        rows: '4',
      },
    },
  }

  const assetsTableColumns = [
    {
      key: 'order_type',
      label: 'Type',
    },
    {
      key: 'sku',
      label: 'SKU',
    },
    {
      key: 'asset_id',
      label: 'Asset ID',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'purpose',
      label: 'Purpose',
    },
    {
      key: 'request_by',
      label: 'Requested by',
    },
  ]

  const autoSuggestTableColumns = [
    {
      key: 'name',
      label: 'Name',
      getValue: (value, data) => `${data.product.name}`,
    },
    {
      key: 'sku',
      label: 'SKU',
      getValue: (value, data) => `${data.product.sku}`,
    },
  ]

  return {
    MODULE_NAME,
    assetsTableColumns,
    transferScheduleFields,
    autoSuggestTableColumns,
    transferScheduleObjectForRender,
    ACCESS_TRANSFER_SCHEDULE_ASSET,
    SCHEDULED_TITLE_PREFIX,
    VOID_ACTION,
  }
}
